import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { activity } from '../models/Activity';

import Message from '../../../sharedComponents/Message';

const useStyles = makeStyles((theme: Theme) => createStyles({
  message: {
    marginTop: theme.spacing(10)
  }
}));

export default observer(() => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.SyntheticEvent<any, Event>,
                       index: number) => {
    setTimeout(() => {
      activity.removeMessage(index);
      setTimeout(() => activity.messages.length != 0 ? activity.clearMessages() : null, 300);
    }
      , 300);
  };
  return (
    <div>
      {activity.messages.map((message, index) => (
        <div key={index}>
          <Message
            variant={message.variant}
            duration={12000}
            message={typeof message.message === 'string' ?
              t(message.message) : t(message.message.key, {...message.message.options})}
            className={classes.message}
            forwardCloseEvent={event => handleClose(event, index)}
            index={index}
          />
        </div>
      ))}
    </div>
  );
});
