import { ICardInfo } from '../../../../helpers/typeHelpers';
import { ICustomerPromotion } from '../CustomerPromotion';

export default (self: any) => ({
  get customerPromotionCardInfo(): ICardInfo[] {
    return self.customerPromotionList.map((customerPromotion: ICustomerPromotion, index: number) => ({
      cardHeader: customerPromotion.cardHeader,
      tableInfo: customerPromotion.tableInfo,
      rowActions: customerPromotion.rowActions(index),
      link: customerPromotion.linkComponent
    }));
  },

  get customerPromotionTableHead() {
    return [
  {  id: 'promotionLink', numeric: false, sortable: true, label: 'Promotion Link' },
  {  id: 'picturelink', numeric: false, sortable: true, label: 'Promotion image URL' },
  {  id: 'createdDate', numeric: false, sortable: true, label: 'Created Date' }
    ];
  },

  customerPromotionListCount(): number {
    return self.customerPromotionList.length;
  }
});
