import { ISite } from '../../../helpers/typeHelpers';
import { user } from '../../userManagement/models/User';

const warrantySiteMap: ISite[] = [];
if (user.hasAccessLevel(['WarrantyClaims', 'All'])) {
  warrantySiteMap.push({
      label: 'Warranty Claims',
      path: '/warranty/claims',
      children: [],
      showInMenu: true
    },
    {
      label: 'Create Claim',
      path: '/warranty/create-claim',
      children: [],
      showInMenu: false
    },
    {
      label: 'Order',
      path: '/warranty/id',
      children: [],
      showInMenu: false
    });
  }

export default warrantySiteMap;
