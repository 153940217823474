import { createStyles, makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { createFieldTheme } from '../config/theme';
import { Omit } from '../helpers/typeHelpers';

const useStyles = makeStyles((theme: Theme) => createStyles({
  focused: {
    '& fieldset': {
      borderWidth: '1px !important'
    }
  },
  hovered: {
    '& fieldset': {
      background: 'green'
    }
  },
  labelRoot: {
    fontSize: '0.95em'
  }
}));

interface IProps extends Omit<OutlinedTextFieldProps, 'variant' | 'classes' | 'disabled'> {
  disabled?: boolean | null;
  displayRequiredErrorMessege?: boolean;
  endPlacement?: ReactNode;
  type?: string;
}

const AnnataTextField =
({value, endPlacement, displayRequiredErrorMessege, required, disabled, type, ...props}: IProps) => {
  const fieldTheme = createFieldTheme();
  const fullWidth = props.fullWidth ? props.fullWidth : true;
  let errorMessage: any = null;
  const classes = useStyles();
  const { t } = useTranslation();
  if (displayRequiredErrorMessege) {
    errorMessage = { error: true, helperText: props.helperText || t('This field is required') };
  }
  return (
    <MuiThemeProvider theme={fieldTheme}>
      <TextField
        {...props}
        disabled={!!disabled}
        color='primary'
        variant='outlined'
        type={type || 'text'}
        value={value}
        {...errorMessage}
        required={required && !displayRequiredErrorMessege}
        fullWidth={fullWidth}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot
          }
        }}
        InputProps={{
          classes: {
            focused: classes.focused
          },
          endAdornment: endPlacement
        }}
      />
    </MuiThemeProvider>
  );
};

export default (AnnataTextField);
