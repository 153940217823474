import history from '../../../../config/history';
import { dateFormat } from '../../../../helpers/helperFunctions';
import { IRowAction } from '../../../../helpers/typeHelpers';
export default (self: any) => ({
  get cardHeader() {
    return { key: 'promotionLink', options: { type: self.promotionLink } };
  },

  get tableInfo() {
    return [
      { numeric: false, label: 'Promotion Link',  value: self.promotionLink  },
      { numeric: false, label: 'Picture Link',  value: self.pictureLink  },
      { numeric: false, label: 'Created Date',  value: dateFormat(self.createdDate)  }
    ];
  },

  linkComponent() {
    return `/device/all-device/${self.deviceNumber}`;
  },

  rowActions(index: number): IRowAction[] {
    return [
      { type: 'edit', callback: () =>
       history.push(`/admin/configuration/create-promotion/${index}`), label: 'edit'},
      { type: 'delete', callback: () => self.setShowDeleteDialog(true), label: 'delete', dialogProps: {
        handleDelete: () => self.deleteCustomerPromotion(index),
        title: 'Delete dealer promotion',
        contentTitle:
          `Are you sure you want to delete the dealer promotion,
          this action cannot be undone?`,
        open: self.showDeleteDialog,
        setOpen: self.setShowDeleteDialog
      }}
    ];
  }
});
