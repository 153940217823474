import { Instance, types } from 'mobx-state-tree';

import Actions from './Actions';
import Model from './Model';
import Views from './Views';

const CustomerPromotion = types
  .model('CustomerPromotion', Model)
  .actions(Actions)
  .views(Views);

export default CustomerPromotion;

export const customerPromotion = CustomerPromotion.create({}) as ICustomerPromotion;
export interface ICustomerPromotion extends Instance<typeof CustomerPromotion> {}

export interface ICustomerPromotionResponse extends Omit<
ICustomerPromotion,
  'createdDate'> {
    createdDate: string;
}

export const formatCustomerPromotionResponse = (resp: ICustomerPromotionResponse): ICustomerPromotion => ({
  ...resp,
  createdDate: resp.createdDate ? new Date(resp.createdDate) : null
});
