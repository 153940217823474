import { CircularProgress, createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getSiteMap from '../../../config/sitemap';
import { createLink, openInNewTab } from '../../../helpers/helperFunctions';
import { customerPromotionList } from '../../userManagement/models/CustomerPromotionList';
import { dealerConfiguration } from '../../userManagement/models/DealerConfiguration';
import { user } from '../../userManagement/models/User';
import ImageSlider from '../../userManagement/views/components/ImageSlider';
import { activity } from '../models/Activity';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    marginBottom: 16,
    marginTop: 20,
    '--container-offset': '25%',
    '--container-text-hover-scale': '1.15'
  },
  divider: {
    marginBottom: 16,
    marginTop: 16
  },
  paper: {
    width: 250,
    height: 'calc(100vh - 150px)',
    maxHeight: 500,
    minHeight: 200,
    margin: 10,
    display: 'flex',
    flexFlow: 'column',
    textDecoration: 'none',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
      '& img': {
        transform: 'scale(1.1)'
      },
      '& > $containerText' : {
        minHeight: 'calc(var(--container-offset) * var(--container-text-hover-scale))'

      }
    }
  },
  image: {
    minHeight: 'calc(100% - var(--container-offset))',
    objectFit: 'cover',
    transition: 'transform .5s ease'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  containerText: {
    position: 'relative',
    top: 'calc(var(--container-offset) * -1)',
    zIndex: 'auto',
    color: 'white',
    minHeight: 'var(--container-offset)',
    background: 'rgba(1, 1, 1, 0.75)',
    transition: 'min-height .5s ease'
  },
  textTitle: {
    margin: 10,
    textTransform: 'uppercase',
    wordWrap: 'break-word'
  },
  maintenanceMessage: {
    color: '#e31818'
  },
  circularDisplay: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    width: '100%',
    justifyContent: 'center'
  }

}));

export default observer(() => {
  const { t } = useTranslation();
  const classes = useStyles();
  const siteMap = getSiteMap();

  useEffect(() => {
    if (user.initials === '') {
      user.getCurrentUserFromAPI();
      dealerConfiguration.getDealerPortalFieldsConfiguration();
      customerPromotionList.getCustomerPromotionList();
    }
    if (user && user.dealer) {
      customerPromotionList.getCustomerPromotionList();
    }
    if (user.roles.length === 0) {
      activity.addMessage('You don’t have permission to access the dealer portal. Please contact your dealer admin for the required access roles', 'warning');
    }
  }, []);

  return (
    <div className={classes.root}>
      {!dealerConfiguration.configurationLoading ?
        <Typography variant='h4' gutterBottom>
          {t('Welcome to the dealer portal')}
        </Typography> : null}
      {user.portalMaintenanceMessage && (
        user.portalMaintenanceURL ?
        <div>
          <Divider className={classes.divider} />
          <a  onClick={() => openInNewTab(user.portalMaintenanceURL || '')} href=''>
          <Typography gutterBottom className={classes.maintenanceMessage}>
            {user.portalMaintenanceMessage}
          </Typography>
          </a>
        </div>
        : <div>
        <Divider className={classes.divider} />
        <Typography gutterBottom className={classes.maintenanceMessage}>
          {user.portalMaintenanceMessage}
        </Typography>
      </div>)}
      <Divider className={classes.divider} />
      {dealerConfiguration.configurationLoading ?
        <div className={classes.circularDisplay}>
          <Typography gutterBottom>
            {t('Please wait, loading the configurations...')}
          </Typography>
          <CircularProgress /> </div> :
        <div className={classes.container}>
          {siteMap.map((site, index) => {
            if (site.showInMenu) {
              return (
                <Paper className={classes.paper} component={createLink(site.path || '')} key={index}>
                  {site.image && (
                    <img src={site.image} className={classes.image} />
                  )}
                  <div className={classes.containerText}>
                    <Typography variant='h6' className={classes.textTitle}>
                      {typeof site.label === 'string' ? t(site.label) : site.label}
                    </Typography>
                  </div>
                </Paper>
              );
            }
          })}
        </div>}
      <Divider className={classes.divider} />
      {!dealerConfiguration.configurationLoading ?
        customerPromotionList.customerPromotionList.length > 0 && (
          <ImageSlider slides={customerPromotionList.customerPromotionList} />)
        : null}
    </div>
  );
});
