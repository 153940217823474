import { Modal } from '@material-ui/core';
import React from 'react';

import { config } from '../../models/Config';
import Bar from './Bar';
const html = require('../TermsOfUse/TermsOfUse.html');

export default () => {
  if (config.privacyLink) {
    return (
      <Modal open={true} >
        <iframe src={config.privacyLink} allowFullScreen width='100%' height='100%' />
      </Modal>
    );
  } else {
    return (
      <div dangerouslySetInnerHTML={{__html: html}} />
    );
  }
};
