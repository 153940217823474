import { applySnapshot, flow } from 'mobx-state-tree';
import history from '../../../../config/history';
import { A365Online } from '../../../../helpers/requests';
import { activity } from '../../../base/models/Activity';
import { customerPromotionList } from '../CustomerPromotionList';
import { user } from '../User';

export default (self: any) => ({
    setImageLink(imageLink: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
          if (typeof imageLink === 'string') {
              self.pictureLink = imageLink;
          } else {
              self.pictureLink = imageLink.target.value;
          }
      },
      setPromotionLink(promotionLink: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
          if (typeof promotionLink === 'string') {
              self.promotionLink = promotionLink;
          } else {
              self.promotionLink = promotionLink.target.value;
          }
      },

      setCustomerPrmotion() {
        if (customerPromotionList.selectedCustomerPromotionList) {
        applySnapshot(self, customerPromotionList.selectedCustomerPromotionList);
      } else {
        applySnapshot(self, {});
      }
      },

      cancel: flow(function* cancel() {
        user.setFieldsConfigurationsTab(5);
        history.push('/admin/configuration');
        applySnapshot(self, {});
      }),

      saveAndClose() {
        if (!self.id) {
          self.createCustomerPromotion().then(() => {
            applySnapshot(self, {});
          });
        } else {
          self.updateCustomerPromotion().then(() => {
            applySnapshot(self, {});
          });
        }
        user.setFieldsConfigurationsTab(5);
        history.push('/admin/configuration');
      },

      createCustomerPromotion: flow(function* createCustomerPromotion() {
        try {
          const resp: any = yield A365Online.Post('UserImages', {
            pictureLink: self.pictureLink,
            promotionLink: self.promotionLink
          });
          self.id = resp?.id;
        } catch {
          activity.addMessage('failed to create dealer promotion', 'error');
        }
        return self.id;
      }),

      updateCustomerPromotion: flow(function* updateCustomerPromotion() {
        try {
          yield A365Online.Put(`UserImages/${self.id}`, {
            pictureLink: self.pictureLink,
            promotionLink: self.promotionLink
          });
        } catch {
          activity.addMessage('failed to update dealer promotion', 'error');
        }
        return self.id;
      }),

      clear() {
        self.fetchingCustomerPromotionDetail = false,
        self.pictureLink = null;
        self.promotionLink = null;
        self.id = null;
      },

      setShowDeleteDialog(show: boolean) {
        self.showDeleteDialog = show;
      },

      deleteCustomerPromotion: flow(function* deleteCustomerPromotion(index: number) {
        applySnapshot(self, customerPromotionList.customerPromotionList[index]);
        if (self.id) {
          try {
            yield A365Online.Delete(`UserImages/${self.id}`);
            customerPromotionList.getCustomerPromotionList();
          } catch {
            activity.addMessage('Failed to delete dealer promotion', 'error');
          }
        }
      })
});
