import { isValidURL } from '../../../../helpers/helperFunctions';
import { IAccessLevel } from '../AccessLevel';
import { IDealer } from '../Dealer/interface';
import { IRole } from '../Role';

export default (self: any) => ({

  get dealerList() {
    return self.dealers.map((dealer: IDealer) => ({
      label: `${dealer.customerAccount} - ${dealer.name}`,
      value: dealer
    }));
  },

  getFilteredDealerList(searchString) {
    const searchRegex = new RegExp(`.*${searchString.trim()}.*`, 'gi');
    return self.dealerList.filter(dealer => dealer.label.match(searchRegex));
  },

  get signedIn() {
    return !!self.authToken && self.authToken !== 'null';
  },

  isSignedIn() {
    return !!self.authToken && self.authToken !== 'null';
  },

  get fullName() {
    return self.name;
  },

  get initials() {
    const nameArr = self.fullName ? self.fullName.split('@')[0].split(/[.\s_-]/) : [];
    switch (nameArr.length) {
      case 0:
        return '';
      case 1:
        return nameArr[0].substring(0, 2).toUpperCase();
      default:
        return nameArr.map((part: string) => part.charAt(0).toUpperCase()).slice(0, 2).join('');
    }
  },

  get jwt() {
    if (!self.authToken) {
      return {};
    }

    const base64Url = self.authToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    return JSON.parse(window.atob(base64));
  },

  get cardHeader() {
    return {key: 'NameWithKey', options: { user: self }};
  },

  get tableInfo() {
    return [
      { numeric: false, label: '', value: self.name},
      { numeric: false, label: 'Email', value: self.userEmail},
      { numeric: false, label: 'Master Admin', value: self.masterAdmin},
      { numeric: false, label: 'Disabled', value: self.disabled}
    ];
  },

  get linkComponent() {
    return null;
  },

  get isB2CAuthenticated() {
    return self.b2cAuthenticated;
  },

  hasRole(roleCodes?: string[]) {
    if (typeof roleCodes === 'undefined') {
      return false;
    }

    const userRoleCodes = self.roles.map((role: IRole) => role.code.toLowerCase());

    return roleCodes.some(code => userRoleCodes.indexOf(code.toLocaleLowerCase()) > -1);
  },

  hasAccessLevel(accessLevelCodes?: string[]) {
    if (typeof accessLevelCodes === 'undefined') {
      return false;
    }

    const userAccessLevelCodes = self.accessLevels.map((accessLevel: IAccessLevel) => accessLevel.code.toLowerCase());

    return accessLevelCodes.some(code => userAccessLevelCodes.indexOf(code.toLocaleLowerCase()) > -1);
  },

  isAuthorizedUser(roleCode?: string[]) {
    if (typeof roleCode === 'undefined') {
      return false;
    } else if (roleCode[0] === 'authorizedUser') {
       return true;
    }
    return false;
  },

  devicePath() {
    if (self.hasAccessLevel(['All'])) {
      return '/device/orders';
    }
    if (self.hasAccessLevel(['DeviceOrders'])) {
      return '/device/orders';
    }
    if (self.hasAccessLevel(['DeviceInquiries'])) {
      return '/device/inquiries';
    }
    if (self.hasAccessLevel(['DeviceInvoices'])) {
     return '/device/invoices';
    }
    if (self.hasAccessLevel(['DeliveryReports'])) {
     return '/device/delivery-reports';
    }
    if (self.hasAccessLevel(['DeviceBackOrders'])) {
    return '/device/back-orders';
    }
    if (self.hasAccessLevel(['DeviceStatus'])) {
    return '/device/device-order-status';
    }
    if (self.hasAccessLevel(['AllDevices'])) {
    return '/device/all-devices';
    }
    if (self.hasAccessLevel(['DealerDevices'])) {
    return '/device/dealer-devices';
    }
    if (self.hasAccessLevel(['DeviceQuotation'])) {
      return '/device/quotations';
    }
  },

  partsPath() {
    if (self.hasAccessLevel(['All'])) {
      return '/parts/orders';
    }
    if (self.hasAccessLevel(['PartsOrders'])) {
      return '/parts/orders';
    }
    if (self.hasAccessLevel(['PartsInvoices'])) {
      return '/parts/invoices/id';
      }
    if (self.hasAccessLevel(['ReturnOrders'])) {
      return '/parts/return-orders';
      }
    if (self.hasAccessLevel(['PartsBackOrders'])) {
      return '/parts/back-orders';
      }
    if (self.hasAccessLevel(['PartInquiry'])) {
        return '/parts/part-inquiry';
        }
    if (self.hasAccessLevel(['PartsQuotation'])) {
      return '/parts/quotation';
    }
    if (self.hasAccessLevel(['PartsTransactions'])) {
      return '/parts/transaction';
    }
  },

  servicePath() {
    if (self.hasAccessLevel(['All'])) {
      return '/service/campaigns';
      }
    if (self.hasAccessLevel(['ServiceCampaigns'])) {
      return '/service/campaigns';
      }
    if (self.hasAccessLevel(['Case'])) {
        return '/service/cases';
    }
    if (self.hasAccessLevel(['RegisterMaintenance'])) {
      return '/service/register-maintenance';
  }
  },

  get maintenanceLinkError() {
    if (self.portalMaintenanceURL) {
      return isValidURL(self.portalMaintenanceURL);
    }
    return false;
  }

});
